// Override default variables before the import
$body-color: #000;

$theme-colors: (
  "primary": #146faf,
  "secondary": #e9e9ed,
  "light": white,
  "dark": black,
  "info": #bb6817c7,
);

@import '~bootstrap/scss/bootstrap.scss';

body {
  background-color: map-get($theme-colors, "secondary");
}

.App {
  text-align: left;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: 400;
  font-size: 1.1rem;
  font-family: 'Raleway', sans-serif;
  line-height: 1.8rem;
}

h1, h2, h3, h4, h5 {
  font-family: 'Cormorant Garamond', serif;
  margin-bottom: 1.2rem;
}

h1 {
  font-size: 2.5rem;

  @include media-breakpoint-up(md) {
    font-size: 4.5rem;
  }
}

h2 {
  font-size: 2.1rem;

  @include media-breakpoint-up(md) {
    font-size: 4.2rem;
  }
}

h3 {
  font-size: 1.8rem;

  @include media-breakpoint-up(md) {
    font-size: 2.8rem;
  }
}

h4 {
  font-size: 1.6rem;
}

.btn {
  font-weight: 600;
  border-color: inherit;
}

.contact {
  .mail {
    color: map-get($theme-colors, "primary");
    font-weight: bold;
  }
}

.navBar {
  .logo {
    font-size: 1.2rem;
    font-family: 'Cormorant Garamond', serif;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }

  .navLink {
    padding-bottom: 0.1em;

    &:hover {
      color: inherit;//map-get($theme-colors, "info");
      text-decoration: inherit;
    }

    &.active {
      border-bottom: 1px solid;
    }
  }
}

.block {
  padding-top: 6.6vmax;
  padding-bottom: 6.6vmax;

  >:first-child {
    padding-top: 17px;
  }
}

.primary {
  color: map-get($theme-colors, "light");
  background-color: map-get($theme-colors, "primary");

  .btn {
    color: map-get($theme-colors, "light");
    background-color: map-get($theme-colors, "primary");

    &:active {
      color: map-get($theme-colors, "primary") ;
      background-color: map-get($theme-colors, "light") ;
      border-color: map-get($theme-colors, "primary") ;
    }
    &:hover {
      border-color: map-get($theme-colors, "primary"); 
    }
  }
}

.secondary {
  color: map-get($theme-colors, "dark");
  background-color: map-get($theme-colors, "secondary");

  .btn {
    color: map-get($theme-colors, "light");
    background-color: map-get($theme-colors, "primary");

    &:active {
      color: map-get($theme-colors, "light") ;
      background-color: map-get($theme-colors, "primary") ;
      border-color: map-get($theme-colors, "light") ;
    }
    &:hover {
      border-color: map-get($theme-colors, "light"); 
    }
  }
}

.accent {
  color: map-get($theme-colors, "light");
  background-color: map-get($theme-colors, "info");

  .btn {
    color: map-get($theme-colors, "info");
    background-color: map-get($theme-colors, "light");

    &:active {
      color: map-get($theme-colors, "light") ;
      background-color: map-get($theme-colors, "info") ;
      border-color: map-get($theme-colors, "light") ;
    }
    &:hover {
      border-color: map-get($theme-colors, "light"); 
    }
  }
}
